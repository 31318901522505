// Add quotation marks to blockquote text
$(".blockquote__quote").each(function() {
	const $this = $(this);

	const allParas = $this.find("p");
	allParas.each(function() {
		const paraText = $(this).text();
		$(this).text("‘" + paraText);
	});

	const lastPara = $this.find("p:last");
	const lastParaText = lastPara.text();
	lastPara.text(lastParaText + "’");
});