// The filter form needs to jump in and out of the accordion
// depending on whether the viewport is >=1024px or not

document.addEventListener("DOMContentLoaded", function () {
	const filterForm = document.querySelector("#filterForm");

	if (filterForm) {
		const filterFormAccordion = document.querySelector("#filterFormAccordion");
		const filterFormAccordionBody = document.querySelector(
			"#filterFormAccordionBody"
		);

		function moveFilterForm() {
			if (window.innerWidth >= 1024) {
				if (filterForm.parentNode === filterFormAccordionBody) {
					filterFormAccordion.parentNode.insertBefore(
						filterForm,
						filterFormAccordion.nextSibling
					);
				}
			} else {
				if (filterForm.parentNode !== filterFormAccordionBody) {
					filterFormAccordionBody.appendChild(filterForm);
				}
			}
		}

		moveFilterForm();

		window.addEventListener("resize", moveFilterForm);
	}
});
