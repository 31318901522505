var copyLinkBtn = document.querySelectorAll(".copy-link");

for (var i = 0; i < copyLinkBtn.length; i++) {
  var pageUrl = copyLinkBtn[i].dataset.copyLink;
  // on click
  copyLinkBtn[i].onclick = function() {
    clickToCopy(this, pageUrl);
  };

  // on enter key
  copyLinkBtn[i].addEventListener("keypress", function(e) {
    if (e.key === "Enter") {
      clickToCopy(this, pageUrl);
    }
  });
}

function clickToCopy(button, textValue) {
  navigator.clipboard.writeText(textValue);
  // change text
  button.querySelector(".copy-link-trigger").style.display = "none";
  button.querySelector(".copy-link-action").style.display = "block";
  setTimeout(() => {
    button.querySelector(".copy-link-trigger").style.display = "block";
    button.querySelector(".copy-link-action").style.display = "none";
  }, 3000);
}