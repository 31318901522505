document.addEventListener("DOMContentLoaded", function () {
	const links = document.querySelectorAll('#siteEmbeds a');

	links.forEach(link => {
		link.addEventListener('click', function (e) {
			e.preventDefault();  // Stop the default link behavior
			const url = this.href;  // Get the clicked link's URL
			window.top.location.href = url;  // Redirect the parent window
		});
	});
});