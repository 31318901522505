function handlePasswordRevealToggle(event) {
	const checkbox = event.target;
	const parentLabel = checkbox.closest("label");
	const passwordInput = parentLabel.previousElementSibling;
	const span = checkbox.nextElementSibling;

	if (
		passwordInput &&
		passwordInput.tagName.toLowerCase() === "input" &&
		(passwordInput.type === "password" || passwordInput.type === "text")
	) {
		passwordInput.type = checkbox.checked ? "text" : "password";

		if (span && span.tagName.toLowerCase() === "span") {
			span.textContent = checkbox.checked ? "Hide" : "Show";
		}
	}
}

const passwordRevealToggles = document.querySelectorAll(
	".password-reveal-toggle"
);

passwordRevealToggles.forEach((checkbox) => {
	checkbox.addEventListener("change", handlePasswordRevealToggle);
});
