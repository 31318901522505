$("#levelOneTopicSelect").on("change", function () {
	const topLevelTopicId = $(this).find(":selected").val();

	$(".level-two-topic-checkboxes").each(function () {
		$(this).removeClass("flex");
		$(this).addClass("hidden");
		$("input[name='topic[]']").prop("checked", false);
	});

	if (topLevelTopicId) {
		$("#levelTwoTopicCheckboxes" + topLevelTopicId)
			.removeClass("hidden")
			.addClass("flex");
	}
});

$("#levelOneLocationSelect").on("change", function () {
	const topLevelLocationId = $(this).find(":selected").val();

	$(".level-two-location-checkboxes").each(function () {
		$(this).removeClass("flex");
		$(this).addClass("hidden");
		$("input[name='location[]']").prop("checked", false);
	});

	if (topLevelLocationId) {
		$("#levelTwoLocationCheckboxes" + topLevelLocationId)
			.removeClass("hidden")
			.addClass("flex");
	}
});