if(document.querySelector(".testimonial-glide") !== null) {
	var glide = new Glide('.testimonial-glide', {
		type: 'carousel',
		perView: 2,
		gap: 32,
		autoplay: 3000,
		breakpoints: {
			800: {
				perView: 1,
			}
		}
	}).mount()
}

$('.slider-playback').on('click', function (e) {
  e.preventDefault();
  self = $(this);

  if ( self.hasClass('play') ) {
      self.removeClass('play').addClass('pause');
      glide.pause();
      console.log('Paused slideshow.');
  }
  else if ( self.hasClass('pause') ) {
      self.removeClass('pause').addClass('play');
      glide.play();
      console.log('Resuming slideshow.');
  }
});