function processReferences() {
	if (document.querySelector(".contains-ref-shortcode") !== null) {
		const contentElement = document.querySelector(".contains-ref-shortcode");
		let content = contentElement.innerHTML;

		const refRegex = /\[ref\](.*?)\[\/.*?ref\]/g;
		let matches = [];
		let match;
		let refCounter = 1;
		while ((match = refRegex.exec(content)) !== null) {
			matches.push({
				refHTML: match[1],
				originalPositionId: `ref-original-${refCounter++}`,
			});
		}

		if (matches.length > 0) {
			// console.log("Found references:", matches);

			let referencesContainer = document.createElement("div");
			referencesContainer.id = "references";
			referencesContainer.className =
				"pt-3 border-t styled-lists border-borders sm:pt-3-sm";

			let referencesTitle = document.createElement("h2");
			referencesTitle.innerText = "References";
			referencesTitle.className = "mb-2 heading-s sm:mb-2-sm";

			let referencesList = document.createElement("ol");
			referencesList.id = "referencesList";
			referencesList.className = "mb-0 body-xs";

			referencesContainer.appendChild(referencesTitle);
			referencesContainer.appendChild(referencesList);

			const pageFooterMetaElement = document.querySelector("#pageFooterMeta");
			pageFooterMetaElement.prepend(referencesContainer);

			let refCounter = 1;

			content = content.replace(refRegex, function () {
				let originalPositionId = `ref-original-${refCounter}`;
				let anchorMarkup = `<a class="no-underline font-fairgates" id="${originalPositionId}" href="#reference-${refCounter}"><sup class="text-[65%]">[${refCounter++}]</sup></a>`;
				return anchorMarkup;
			});

			let backLinkMobileSvg = `<svg class="inline-block sm:hidden" width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8 7.33333V6H14V12.6667H4.53333L6.93333 15.0667L6 16L2 12L6 8L6.93333 8.93333L4.53333 11.3333H12.6667V7.33333H8Z" fill="currentColor"/></svg>`;
			let backLinkDesktopSvg = `<svg class="hidden sm:inline-block" width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 8.66667V7H17.5V15.3333H5.66667L8.66667 18.3333L7.5 19.5L2.5 14.5L7.5 9.5L8.66667 10.6667L5.66667 13.6667H15.8333V8.66667H10Z" fill="currentColor"/></svg>`;

			matches.forEach(({ refHTML, originalPositionId }, index) => {
				let listItem = document.createElement("li");
				listItem.innerHTML = refHTML;
				listItem.id = `reference-${index + 1}`;

				let backLink = document.createElement("a");
				backLink.href = `#${originalPositionId}`;
				backLink.className =
					"inline-block -translate-y-[.2rem] ml-[.2rem] sm:ml-[.4rem]";
				backLink.innerHTML = backLinkMobileSvg + backLinkDesktopSvg;
				listItem.appendChild(backLink);

				referencesList.appendChild(listItem);
			});

			contentElement.innerHTML = content;
		}
	}
}

document.addEventListener("DOMContentLoaded", processReferences());
